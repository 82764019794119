import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export function WorkshopEventsSection({ title, children }) {
  return (
    <div className="medium-light-gray-bg py-xl-5 py-4" id="tll_events">
      <Container fluid="lg" className="py-xl-5 py-4">
        <Row className="mb-xl-5 mb-4 pb-2">
          <Col xs={12}>
            <h2 className="workshop-event-headline">{title}</h2>
          </Col>
        </Row>
        <Row>{children}</Row>
      </Container>
    </div>
  );
}

export function WorkshopEvent({
  image,
  dates,
  title,
  classes,
  buttonText,
  children,
  cost,
  student
}) {
  return (
    <Col lg={6} xs={12} className={`${classes} tll-event mb-4`}>
      <div className="white-bg p-3">
        <img
          src={image}
          alt={`${title} event`}
          className="img-fluid mb-lg-4 pb-2"
        />
        <Row>
          <Col xs={12} className="mb-25">
            <div className="workshop-event-dates">
              <h5>{dates}</h5>
            </div>
          </Col>
          {
            student ?
            <>
            <Col xs={12} className="mb-25">
              <div className="workshop-students">
                <h5>Students Only</h5>
              </div>
            </Col>
            </>
            :
            <></>
          }
          <Col xs={12} className={cost ? `mb-1 pb-1` : `mb-3 pb-1`}>
            <h3 className="workshop-event-title mb-0">{title}</h3>
          </Col>
          {cost ?
            <>
              <Col xs={12} className="mb-3 pb-1">
                <h5 className="workshop-event-cost mb-0">{cost}</h5>
              </Col>
            </>
            :
            <></>
          }
          <Col xs={12} className="mb-3 pb-1">
            <p className="workshop-event-description mb-0">{children}</p>
          </Col>
          <Col xs={12}>
            <p>
              <a href="#tllRegistration" className="btn btn-secondary">
                {buttonText}{" "}
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="#0078ae"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                    fill="#0078ae"
                  ></path>
                </svg>
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
